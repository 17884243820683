import Vue from "vue";
// import VueCompositionAPI from '@vue/composition-api';
// BootstrapVue
import {
  BootstrapVue,
  ToastPlugin,
  ModalPlugin,
  IconsPlugin,
  VBScrollspyPlugin,
  DropdownPlugin,
} from "bootstrap-vue";
// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas, faCookieBite } from "@fortawesome/free-solid-svg-icons";

// import {  } from "@fortawesome/free-solid-svg-icons";

// moment
import moment from "moment";
import { extendMoment } from "moment-range";
// Datapicker
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";

import i18n from "@/libs/i18n";

// Global Components
import "./global-components";

// 3rd party plugins
import "@/libs/portal-vue";
import "@/libs/toastification";
import "@/libs/vue-select";

import "@/@core/scss/vue/libs/quill.scss";

// SocketIO
import VueSocketIO from "vue-socket.io";
import SocketIO from "socket.io-client";

import VueVideoPlayer from "vue-video-player";
import VueScrollTo from "vue-scrollto";
import VueMoment from "vue-moment";
import VueCookies from "vue-cookies";
import VueCookiesConsent from "@norvikit/vue-cookies-consent";

import "video.js/dist/video-js.css";
// import axios from "axios";
import CKEditor from "@ckeditor/ckeditor5-vue2";

/* Bugsnag */
import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";

import ChatBotService from "@/services/ChatBotService";

import App from "./App.vue";
import store from "./store";

// Initialize Vue Cookies before router
Vue.use(VueCookies, { expire: "1y" });

import router from "./router";
import './registerServiceWorker';

// Custom directives
import BadgeSmartText from '@/@core/directives/badge-smart-text';

// Import smart badges plugin
import SmartBadges from '@/@core/plugins/smart-badges';

if (process.env.VUE_APP_ENV !== "development") {
  
  // 1 - GOOGLE TAG MANAGER

  const measurementID = process.env.VUE_APP_ENV === "production" ? 'G-655D3DNP1F' : 'G-PT4BVEQFWD';

  const gtag1 = document.createElement('script');
  gtag1.src = `https://www.googletagmanager.com/gtag/js?id=${measurementID}`;
  gtag1.type = "text/javascript";
  //gtag1.defer = true;
  gtag1.setAttribute("async", "");
  document.head.appendChild(gtag1);
  //document.getElementsByTagName("head")[0].appendChild(gtag1);

  const gtag2 = document.createElement('script');
  gtag2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${measurementID}');`;
  document.head.appendChild(gtag2);
  
  //Nectios GTM
  const googleTagNectiosHead = document.createElement('script');
  googleTagNectiosHead.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-MP6D4M4H');`;
  document.head.appendChild(googleTagNectiosHead);

  const googleTagNectiosBody = document.createElement('noscript');
  googleTagNectiosBody.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MP6D4M4H" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
  document.body.appendChild(googleTagNectiosBody);

  window.dataLayer = window.dataLayer || [];

  // function gtag() {
  //   dataLayer.push(arguments);
  // }
  // gtag("js", new Date());
  // gtag("config", measurementID);

  // axios.get("https://api.ipify.org").then((result) => {
  //   router.afterEach((to, from) => {
  //     gtag("config", measurementID, {
  //       page_title: `${to.params?.communityId},${result.data}`,
  //       page_path: `${to.params?.communityId},${result.data}`,
  //     });
  //   });
  // });

  // router.afterEach((to, from) => {
  //   gtag("config", measurementID, {
  //     page_title: to.path,
  //     page_path: to.path,
  //   });
  // });

  // 2 - BUGSNAG
  Bugsnag.start({
    apiKey: process.env.VUE_APP_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginVue()],
    releaseStage: process.env.VUE_APP_ENV,
    onError(event) {
      return (
        event.errors[0].errorMessage !== "Network Error" && event.errors[0].errorMessage !== "Unexpected token '<'"
      );
    },
  });
  Bugsnag.getPlugin("vue").installVueErrorHandler(Vue);
  const bugsnagVue = Bugsnag.getPlugin("vue");
  bugsnagVue.installVueErrorHandler(Vue);
}

// VueSocketIO
const options = {
  transports: ["websocket", "polling"],
  auth: {
    token: Vue.$cookies.get("accessToken"),
  },
};

Vue.use(
  new VueSocketIO({
    debug: false,
    connection: SocketIO(process.env.VUE_APP_API_SOCKETS, options),
  }),
);

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.use(IconsPlugin);
Vue.use(VBScrollspyPlugin);
Vue.use(DropdownPlugin);
Vue.use(VueCookiesConsent);

// Composition API
// Vue.use(VueCompositionAPI);

// Vue scrollTO
Vue.use(VueScrollTo);

// DateTimePicker
Vue.component("VueCtkDateTimePicker", VueCtkDateTimePicker);

// LANGUAGES - LOCALE
require("moment/locale/en-gb");
require("moment/locale/ca");
require("moment/locale/es"); // the last one is the default

Vue.use(VueMoment, {
  moment: extendMoment(moment),
});

Vue.use(VueVideoPlayer);

const AddToCalendar = require("vue-add-to-calendar");

Vue.use(AddToCalendar);

// Start CKeditor
Vue.use(CKEditor);

// BootstrapVue
Vue.use(BootstrapVue);

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

Vue.config.productionTip = false;

// Font awesome
Vue.component("font-awesome-icon", FontAwesomeIcon);
library.add(fas);
library.add(faCookieBite);

// Register global directives
Vue.directive('badge-smart-text', BadgeSmartText);

// Register global components
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("ctk-date-time-picker", VueCtkDateTimePicker);

// Use smart badges plugin
Vue.use(SmartBadges);

new Vue({
  router,
  store,
  provide: {
    $chatBot: ChatBotService,
  },
  i18n,
  render: (h) => h(App),
}).$mount("#app");
